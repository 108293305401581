import { useRuntimeConfig } from 'src/services/RuntimeConfig'
import { boot } from 'quasar/wrappers'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { date as DateFormatter } from 'quasar'

import {
  BuzzSentiment,
  CountriesInner,
  EntityType,
  ListsInner,
  Message,
  PersonV6,
  QuotesForTitlesQuotesInner,
  SearchMessages,
  SearchMessagesStatisticsTop10,
  SearchMessagesStatisticsTop10Entities,
  TitleInformation
} from '@stockpulse/typescript-axios'
import { differenceInMilliseconds } from 'date-fns'
import { AxiosRequestManager, CancellationPolicy, RouteThrottleConfig } from 'src/services/AxiosRequestManager'
import {
  AssetEntityType,
  ExchangeData,
  InventorySector,
  InventoryTitle,
  KeyEvent,
  UiPreferences,
  UserOpts
} from 'stores/inventory-store'
import { DEFAULT_REPORT_MODELS, getReportModel, ReportModel, ReportModelType } from 'src/helper/ReportHelper'
import { AuthorHistoryData } from 'stores/author-view-store'
import {
  SearchMessagesStatisticsAuthorAnalytics
} from '@stockpulse/typescript-axios/types/search-messages-statistics-author-analytics'
import { MessageLanguage, MessageType } from 'src/helper/MessageHelper'
import { Title } from 'src/types/Title'
import { TitleChartData } from 'src/types/TitleChartData'
import { TitleHighlight } from 'src/types/TitleHighlight'
import { StandardTriggersInner } from '@stockpulse/typescript-axios/types/standard-triggers-inner'
import { EarningsTitlesInner } from '@stockpulse/typescript-axios/dist/types/earnings-titles-inner'
import { StreamPost } from 'src/types/StreamPosts'
import { AsyncData } from 'components/models'

export enum LevelType {
  FULL = 'full',
  NONE = 'none',
  PARTIAL = 'partial'
}

export interface TurnstileOptions {
  sitekey: string,
  theme: 'light' | 'dark' | 'auto',
  language: string,
  size: string,
  callback(token:string) : void,
  action: string,
  appearance: string,
  'error-callback'(code: string): void,
  'unsupported-callback'(): void
}

declare global {
  interface Window {
    turnstile: {
      reset(): void;
      remove(widgetId: string): void;
      render(htmlRef: HTMLDivElement, options: TurnstileOptions): string;
    };
    ['cfTurnstileOnLoad']: unknown;
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance
    $api: AxiosInstance
  }
}

export interface FilterSetting {
  field: string;
  value: string | string[] | number | number[];
  comparator: 'gt' | 'lt' | 'gte' | 'lte' | 'eq'
}

export type FilterSettingsList = FilterSetting[];

export interface TitleFilter {
  asset: number[],
  list: number[],
  sector: number[],
  exchange: number[],
  region: string[],
  country: string[],
  type: EntityType[]
}

export type ViewFilter = TitleFilter & {keyEvent: number[]}

export type ViewFilterKeys = keyof ViewFilter

export interface MessageFilterEntities {
  messageType: MessageType[],
  messageLanguage: MessageLanguage[],
  authorType: string[],
  keyEvent: KeyEvent[],
  asset: InventoryTitle[]
}

export interface ViewFilterEntities {
  list: ListsInner[],
  sector: InventorySector[],
  exchange: ExchangeData[],
  country: CountriesInner[],
  region?: string[],
  type: AssetEntityType[],
  keyEvent?: KeyEvent[],
  asset?: InventoryTitle[],
}

export interface SolrTitleFilters {
  filters: FilterSetting[],
  add_to_search: boolean,
  restrict_response: boolean
}

export interface HistoryChartData {
  history: Array<{ t: number, Buzz: number, Sentiment: number }>;
  reportModel: ReportModel;
}

export interface HistoryQuoteData {
  history: Array<QuotesForTitlesQuotesInner>
  reportModel: ReportModel
}

export interface TitleFromFilter {
  id: number;
  country?: string;
  listed_at?: number[];
  sector?: number;
  n?: string;
}

export interface TitleSummary {
  summary: string;
  messages: Message[];
  highlights: TitleHighlight[]
  publishDate?: number
}

export interface MessagesSummary {
  executive_summary?: string;
  messages?: Message[];
  executive_summary_highlights?: TitleHighlight[]
}

export enum MessagesCore {
  Messages ='messages',
  Messages24h = 'messages24h'
}

export interface TitlesBuzzSentiment { [key: number]: BuzzSentiment }

// TODO_NEXT: Update API Typedefinitions
declare module '@stockpulse/typescript-axios' {
  interface SearchMessagesStatisticsTop10Titles {
    [key: number]: number
  }

  interface SearchMessagesStatisticsTop10AuthorsInner {
    [key: number]: number
  }

  interface SearchMessagesStatisticsTop10KeyEvents {
    [key: number]: number
  }

  interface SearchMessagesStatisticsCounts {
    pos?: number,
    neg?: number,
    neu?: number
  }

  interface SearchMessagesStatisticsHistoryInner {
    pos: number,
    neg: number,
    neu: number
  }

  interface AuthorStatistics {
    history: AuthorHistoryData[]
  }

  interface TitleInformation {
    url: string,
    next_earnings?: string
  }
  interface ListsInner {
    icon: string
  }
  interface ListsInner {
    country: string
  }
  interface TitleInventoryInner {
    buzz?: number,
    sentiment?: number,
    b_o?: number,
    s_o?: number,
    t: number
  }

  interface TitleMessages {
    [key: number]: {total:number, pos:number, neg:number, sentiment:number}
  }

  interface SearchMessagesStatistics {
    'oldest_message'?: Message,
    'youngest_message'?: Message,
    'average_sentiment'?: number,
    'counts'?: SearchMessagesStatisticsCounts,
    'top10'?: SearchMessagesStatisticsTop10,
    'history'?: Array<SearchMessagesStatisticsHistoryInner>,
    'author_analytics'?: SearchMessagesStatisticsAuthorAnalytics,
    'title_messages'?: TitleMessages
  }

  interface HistoricBuzzSentiment {
    'titles'?: unknown[]
  }
}

// TODO_NEXT: Update API Typedefinitions
declare module '@stockpulse/typescript-axios/types' {
  interface StandardTriggersInner {
    icon?: string,
    release_date?: string
  }
}

const runtimeConfig = useRuntimeConfig()

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({
  baseURL: runtimeConfig.API_BASE_URL,
  headers: {
    'x-app-version': process.env.APP_VERSION_NAME,
    // TODO_NEXT: this prevents caching to avoid Edge cross-origin cache problem
    // 1. open app and login
    // 2. open app from another origin
    // 3. some requests will fail due to cross-origin caching
    'Cache-Control': 'private, no-cache, no-store, must-revalidate'
  }
})

const routeThrottleConfigs: RouteThrottleConfig[] = [
  { route: '/v6', concurrency: 4 },
  { route: '', concurrency: 10 }
]

const axiosRequestManager = new AxiosRequestManager(routeThrottleConfigs)

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API

  api.interceptors.request.use((config: AxiosRequestConfig) => axiosRequestManager.interceptRequest(config))

  api.interceptors.response.use(
    (response: AxiosResponse) => axiosRequestManager.interceptResponse(response),
    (error) => axiosRequestManager.interceptResponseError(error)
  )
})

async function fetchInfosForTitles (
  titleIds: number[],
  cancellationPolicy?: CancellationPolicy): Promise<TitleInformation[]> {
  if (!titleIds.length) {
    return []
  }
  const response = await api.get(
    `/v6/titles/${titleIds.join(',')}/info`, { cancellationPolicy })
  return Array.isArray(response.data) ? response.data : (response.data ? [response.data] : [])
}

async function fetchStatistics (
  startDate: Date,
  endDate: Date,
  titleIds: number[],
  sourceFilter: string,
  keyEventIds?: number[],
  query? :string,
  lang? : string[],
  type?:string[],
  topLimit?:number,
  authors?: {source:string, name:string}[],
  titleFilters?:SolrTitleFilters
)
  : Promise<SearchMessages|undefined> {
  const body = {
    startDate,
    endDate,
    include_stats: true,
    core: getCoreToUse(startDate),
    titles: titleIds,
    source: sourceFilter,
    add_icon: true,
    key_events: keyEventIds,
    query,
    lang,
    type: type?.flatMap(str => str.includes(',') ? str.split(',') : str),
    top_limit: topLimit,
    authors,
    title_filters: titleFilters
  }
  const response = await api.post('/v6/messages/search', body)
  return response.data
}

export interface AuthorFetch {
  source:string,
  name:string
}

async function fetchSolrMessages (
  startDate: Date,
  endDate: Date,
  titleIds: number[],
  sourceFilter: string,
  keyEventIds?: number[],
  type?: string[],
  query?:string,
  lang?: string[],
  score?: number,
  authors?: AuthorFetch[],
  primaryTitle?: number,
  limit?: number
)
  : Promise<Array<Message>> {
  const body = {
    startDate,
    endDate,
    core: getCoreToUse(startDate),
    titles: titleIds,
    source: sourceFilter,
    add_icon: true,
    key_events: keyEventIds,
    type: type?.flatMap(str => str.includes(',') ? str.split(',') : str),
    query,
    lang,
    score,
    authors,
    primary_title: primaryTitle
  }

  const url = '/v6/messages/search' + (limit !== undefined ? `?limit=${limit}` : '')
  const response = await api.post(url, body)
  return response.data?.messages || []
}

interface SearchMessagesWithTopics {
  /**
   *
   * @memberof SearchMessages
   */
  'topics_list': string[];
}

async function fetchTopicsList (startDate: Date, endDate: Date, titleIds: number[],
  sourceFilter: string, keyEventIds?: number[])
  : Promise<SearchMessagesWithTopics|undefined> {
  const body = {
    startDate,
    endDate,
    include_topics_list: true,
    core: getCoreToUse(startDate),
    titles: titleIds,
    source: sourceFilter,
    key_events: keyEventIds
  }

  const response = await api.post(
    '/v6/messages/search',
    body,
    { cancellationPolicy: CancellationPolicy.OnContextSwitch }
  )
  return response.data
}

async function fetchSummary (startDate: Date, endDate: Date, titleIds: number[],
  keyEventIds?: number[]): Promise<TitleSummary> {
  const body = {
    startDate,
    endDate,
    core: getCoreToUse(startDate),
    titles: titleIds,
    limit: 10,
    type: 'news',
    include_stats: false,
    order_by: 'score',
    include_exec_summary: true,
    highlight_titles_in_summary: true,
    key_events: keyEventIds
  }
  const response = await api.post(
    '/v6/messages/search',
    body,
    { cancellationPolicy: CancellationPolicy.OnContextSwitch }
  )
  const fetchedSummary = response.data as MessagesSummary
  return {
    summary: fetchedSummary?.executive_summary ?? '',
    messages: fetchedSummary?.messages ?? [],
    highlights: fetchedSummary?.executive_summary_highlights ?? []
  }
}
async function fetchPreGeneratedSummary (titleId: number): Promise<TitleSummary> {
  const queryParams = {
    stream: 'ai-executive-summaries',
    language: 'en',
    format: 'json',
    highlight_titles_in_text: true,
    plaintext: true
  }
  const response = await api.get(
    `/v6/newsbot/titles/${titleId}`,
    {
      params: queryParams,
      cancellationPolicy: CancellationPolicy.OnContextSwitch
    })

  const summary: string = response.data?.rss?.channel?.item[0]?.description['%']
  const publishDateString: string = response.data?.rss?.channel?.item[0]?.pubDate
  const highlights: TitleHighlight[] = response.data?.rss?.channel?.item[0]?.text_highlights || []

  if (summary === undefined) {
    return { summary: '', messages: [], highlights: [] }
  }

  const publishDate = publishDateString ? new Date(publishDateString).getTime() : undefined

  return { summary, messages: [], highlights, publishDate }
}

async function fetchChartData (source: string, startDate: Date,
  endDate: Date, titleIds: number[], isExtendedTimeRange = true): Promise<HistoryChartData> {
  const reportModel = getReportModel(source, startDate, endDate, isExtendedTimeRange)

  const postBody = {
    datasets: [
      {
        feature: 'Buzz/Sentiment',
        fields: [
          {
            field: 'buzz',
            name: 'Buzz'
          },
          {
            field: 'sentiment',
            name: 'Sentiment'
          }
        ],
        item: 'Buzz Reports',
        report_model: reportModel.id,
        titles: titleIds,
        type: 'column'
      }
    ],
    filter: [
      {
        comparator: 'eq',
        field: 'id',
        value: titleIds
      }
    ],
    identifiers: [
      'id'
    ]
  }

  const queryParams = {
    preview: false,
    startDate,
    endDate
  }

  try {
    const response = await api.post(
      '/v6/datasets/history',
      postBody,
      { params: queryParams }
    )

    return { ...response.data, reportModel }
  } catch (error) {
    return { history: [], reportModel }
  }
}

async function fetchQuoteChartData (source: string, startDate: Date, endDate: Date, titleIds: number[],
  isExtendedTimeRange = true): Promise<{ history: Array<QuotesForTitlesQuotesInner>, reportModel: ReportModel }> {
  const reportModel = getReportModel(source, startDate, endDate, isExtendedTimeRange)

  if (reportModel.type === ReportModelType.DAILY) {
    return { ...(await fetchDailyQuoteChartData(startDate, endDate, titleIds)), reportModel }
  }

  return { ...(await fetchIntradayQuoteChartData(startDate, endDate, titleIds)), reportModel }
}

async function fetchIntradayQuoteChartData (startDate: Date, endDate: Date,
  titleIds: number[]): Promise<{ history: Array<QuotesForTitlesQuotesInner> }> {
  const queryParams = {
    startDate,
    endDate,
    partition: DEFAULT_REPORT_MODELS.realtime.name,
    data_options: 'ohlcv'
  }

  const response = await api.get(
    `/v6/titles/${titleIds.join(',')}/intraday`,
    { params: queryParams }
  )

  return { history: response.data.quotes }
}

async function fetchDailyQuoteChartData (startDate: Date, endDate: Date,
  titleIds: number[]): Promise<{ history: Array<QuotesForTitlesQuotesInner> }> {
  const postBody = {
    datasets: [
      {
        feature: 'Price Data',
        fields: [
          { field: 'open', name: 'o' },
          { field: 'high', name: 'h' },
          { field: 'low', name: 'l' },
          { field: 'close', name: 'c' },
          { field: 'volume', name: 'v' }
        ],
        item: 'Daily Quotes',
        report_model: DEFAULT_REPORT_MODELS.daily.id,
        titles: titleIds
      }
    ],
    filter: [
      {
        comparator: 'eq',
        field: 'id',
        value: titleIds
      }
    ],
    identifiers: [
      'id'
    ]
  }

  const queryParams = { preview: false, startDate, endDate }

  const response = await api.post('/v6/datasets/history', postBody, { params: queryParams })
  return response.data
}

function getCoreToUse (startDate: Date): string {
  return differenceInMilliseconds(new Date(), startDate) < 24 * 60 * 60 * 1000
    ? MessagesCore.Messages24h
    : MessagesCore.Messages
}

export interface ReferencedPersonLabel {
  label: string,
  value: number,
  id: number
}

export interface ReferencedPersons {
  statistics: ReferencedPersonLabel[],
  entities: PersonV6[]
}

async function extractReferencedPersons (top10Entities : SearchMessagesStatisticsTop10Entities)
  : Promise<ReferencedPersons> {
  const entityIds = Object.entries(top10Entities).map(
    (value) => parseInt(value[0])
  )
  const entities = await fetchEntities(entityIds)

  const statistics = Object.entries(top10Entities).map((value) => {
    return {
      label: getEntityName(parseInt(value[0]), entities),
      value: value[1] as number,
      id: parseInt(value[0])
    }
  })
  return {
    statistics,
    entities
  }
}

async function fetchEntities (entityIds: number[]) : Promise<PersonV6[]> {
  if (entityIds.length === 0) {
    return []
  }
  const response = await api.get(`/v6/entities/${entityIds.join(',')}?format=json`)
  return response.data
}

async function updateUserOpts (
  currentUserOpts: AsyncData<UserOpts>,
  uiPreferences: UiPreferences
) : Promise<UserOpts|undefined> {
  const newUiPreferences = {
    ...currentUserOpts.value?.uiPreferences,
    markets: uiPreferences.markets,
    titleTypes: uiPreferences.titleTypes
  }
  const newUserOpts = {
    ...currentUserOpts.value,
    uiPreferences: newUiPreferences
  }

  const body = { opts: JSON.stringify(newUserOpts) }
  const response = await api.post('/v6/userdata/opts', body, { cancellationPolicy: CancellationPolicy.OnLogout })
  if (response.status === 200) {
    return newUserOpts
  }
  return undefined
}

function getEntityName (entityId: number, entities: Array<{ entity_id: number, n: string }>): string {
  return entities?.filter((entity) => entity.entity_id === entityId).at(0)?.n || ''
}

async function fetchTitleChartData (
  title: Title,
  startDate : Date,
  endDate : Date,
  cancellationPolicy ?: CancellationPolicy): Promise<TitleChartData> {
  const response = await api.get(
    `/v6/titles/${title.id}/quotes`,
    {
      params: {
        startDate,
        endDate
      },
      cancellationPolicy
    }
  )
  const quotes: QuotesForTitlesQuotesInner[] = response.data.quotes

  const clearedQuotes: (QuotesForTitlesQuotesInner & {
    t: number,
    c: number
  })[] = quotes.filter(quote => quote.t !== undefined && quote.c !== undefined) as (QuotesForTitlesQuotesInner & {
    t: number,
    c: number
  })[]
  clearedQuotes.sort((a, b) => {
    return a.t - b.t
  })

  const dataSeries: (number | string)[][] = clearedQuotes.map(
    quote => [DateFormatter.formatDate(quote.t * 1000, 'YYYY-MM-DD'), quote.c]
  )

  const minDataValue = Math.min(...dataSeries.map(dataPoint => dataPoint[1] as number))
  const maxDataValue = Math.max(...dataSeries.map(dataPoint => dataPoint[1] as number))

  let rounding = 1000
  while (maxDataValue - minDataValue < rounding) {
    rounding /= 10
  }

  return {
    titleId: title.id,
    titleName: title.name,
    chartDataSeries: dataSeries,
    minValue: Math.floor(minDataValue / rounding) * rounding,
    maxValue: Math.ceil(maxDataValue / rounding) * rounding,
    lastQuote: clearedQuotes.slice(-1)[0],
    secondLastQuote: clearedQuotes.slice(-2)[0],
    currency: title.currency || ''
  }
}

async function filterTitles (
  query: FilterSettingsList, limit: number,
  sortBy: string, partition?: string,
  cancellationPolicy?: CancellationPolicy
): Promise<TitleFromFilter[]> {
  const params = {
    limit,
    sort_by: sortBy,
    partition
  }
  const response = await api.post('/v6/titles', query, {
    params,
    headers: {
      'Content-Type': 'application/json'
    },
    cancellationPolicy
  })
  return response.data
}

async function fetchStandardTriggers (
  titleId: number,
  startDate: Date,
  endDate: Date,
  limit: number = 20,
  ruleTypes: string[]
): Promise<StandardTriggersInner[]> {
  const response = await api.get(`/v6/standard_triggers/${titleId}`, {
    params: { startDate, endDate, limit, rule_types: ruleTypes }
  })
  return response.data as StandardTriggersInner[]
}

async function fetchEarnings (titleId: number, startDate: Date, endDate: Date): Promise<Array<EarningsTitlesInner>> {
  const response = await api.get(`/v6/titles/${titleId}/earnings`, {
    params: { startDate, endDate }
  })
  return response.data as EarningsTitlesInner[]
}

export interface Executive {
    firstName?: string
    middleName?: string
    lastName: string
    name: string
    city?: string
    birthdate?: string
    role: string
    entryDate: string
}

async function fetchExecutives (titleId: number): Promise<Array<Executive>> {
  const response = await api.get(
    `/v6/titles/${titleId}/positions`,
    { cancellationPolicy: CancellationPolicy.OnContextSwitch }
  )
  const executives = (response.data?.company_register ?? []) as Omit<Executive, 'name'>[]

  return executives.map((executive) => {
    let name = executive.lastName

    if (executive.middleName || executive.firstName) {
      name += ', ' + [executive.firstName, executive.middleName].filter((item) => item !== undefined).join(' ')
    }
    return { ...executive, name }
  })
}

export interface Subsidiary {
    subsidiaryName: string
    share: number
}

export interface SubsidiaryCollection {
    year: number,
    subsidiaries: Array<Subsidiary>
}

async function fetchSubsidiaries (titleId: number): Promise<SubsidiaryCollection> {
  const response = await api.get(
    `/v6/titles/${titleId}/subsidiaries`,
    { cancellationPolicy: CancellationPolicy.OnContextSwitch }
  )
  return (response.data?.federal_gazette ?? {}) as SubsidiaryCollection
}

async function fetchStreamPosts (
  region: string[],
  titleTypes: string[],
  types: string[],
  limit: number,
  createdBefore?: string,
  addGlobals = true
): Promise<StreamPost[]> {
  let streamPostDate
  if (createdBefore) {
    streamPostDate = `{* TO ${createdBefore}]`
  }

  const response = await api.get(
    '/v6/stream_posts',
    {
      params: {
        region: region.join(','),
        titleTypes: titleTypes.length ? titleTypes.join(',') : undefined,
        limit,
        type: types.length ? types.join(',') : undefined,
        streamPostDate,
        addGlobals
      },
      cancellationPolicy: CancellationPolicy.OnLogout
    }
  )
  return response.data as StreamPost[]
}

async function fetchStreamPost (postUUID : string): Promise<StreamPost> {
  const streamPosts = await fetchStreamPostsByPostUUIDs([postUUID])
  return streamPosts[0]
}

async function fetchStreamPostsByPostUUIDs (postUUIDs : string[]): Promise<StreamPost[]> {
  const response = await api.get(
    `/v6/stream_posts/${postUUIDs.join(',')}`,
    { cancellationPolicy: CancellationPolicy.OnContextSwitch }
  )
  return response.data as StreamPost[]
}

export {
  api,
  axiosRequestManager,
  fetchExecutives,
  fetchSubsidiaries,
  fetchTitleChartData,
  fetchInfosForTitles,
  fetchStatistics,
  fetchTopicsList,
  fetchChartData,
  fetchEarnings,
  fetchSolrMessages,
  fetchQuoteChartData,
  fetchSummary,
  fetchPreGeneratedSummary,
  fetchStandardTriggers,
  filterTitles,
  fetchStreamPosts,
  fetchStreamPost,
  fetchStreamPostsByPostUUIDs,
  extractReferencedPersons,
  getCoreToUse,
  updateUserOpts
}
