import { differenceInDays, Duration, isAfter } from 'date-fns'
import { UserData } from 'src/services/AuthenticationService'
import { storeToRefs } from 'pinia'
import { useAuthUserStore } from 'stores/auth-user-store'
import { Ref } from 'vue'
import { getDurationInDays } from 'src/services/TimeRangeSelectionService'
import { calculateStartDate, RelativeTimeRange } from 'src/helper/DateTimeHelper'
import { useWatchlistStore } from 'stores/watchlist-store'

export enum SubscriptionEnum {
  TRIAL_USER = 'trial',
  BASIC_USER = 'basic',
  PREMIUM_USER = 'premium',
  PLATINUM_USER = 'platinum',
  PROFESSIONAL_USER = 'professional',
}

interface UserPermissions {
  historyMonths: number,
  maxRelativeTimeRangeDays: number,
  aiTextgen: boolean,
  downloadPermitted: boolean,
  maxWatchlistAuthors: number,
  maxWatchlistViews: number,
  subscriptionType: SubscriptionEnum,
  messageType: boolean,
  messageLanguage: boolean,
  authorType: boolean,
  messageAsset: boolean,
  messageKeyEvent: boolean,
  source: boolean,
  displayLists: boolean,
  displayKeyEvents: boolean,
  displayAuthors: boolean
}

export type Feature = keyof UserPermissions

export interface RequiredTimespanOptions {
  start?: Date,
  end?: Date,
  duration?: Duration,
  relativeTimeRange?: RelativeTimeRange
}

export interface RequiredHistoryMonthOptions {
  duration: Duration
}

export interface Requirements {
    requiredFeatures?: Feature[],
    requiredHistoryMonth?: RequiredHistoryMonthOptions,
    requiredTimeSpan?: RequiredTimespanOptions,
    watchlistHasViewCapacity?: boolean,
    watchlistHasAuthorCapacity?: boolean,
    morningBriefingsHasCapacity?: boolean,
    isAlwaysForbidden?: boolean
}

const TRIAL_USER: UserPermissions = {
  historyMonths: 1,
  maxRelativeTimeRangeDays: 7,
  aiTextgen: false,
  downloadPermitted: false,
  maxWatchlistAuthors: 4,
  maxWatchlistViews: 5,
  subscriptionType: SubscriptionEnum.TRIAL_USER,
  messageType: true,
  messageLanguage: true,
  authorType: true,
  messageAsset: true,
  messageKeyEvent: true,
  source: true,
  displayLists: true,
  displayKeyEvents: true,
  displayAuthors: true
}

const BASIC_USER: UserPermissions = {
  historyMonths: 1,
  maxRelativeTimeRangeDays: 3,
  aiTextgen: false,
  downloadPermitted: false,
  maxWatchlistAuthors: 5,
  maxWatchlistViews: 5,
  subscriptionType: SubscriptionEnum.BASIC_USER,
  messageType: false,
  messageLanguage: false,
  authorType: false,
  messageAsset: false,
  messageKeyEvent: false,
  source: false,
  displayLists: false,
  displayKeyEvents: false,
  displayAuthors: false
}

const PREMIUM_USER: UserPermissions = {
  historyMonths: 12,
  maxRelativeTimeRangeDays: 30,
  aiTextgen: false,
  downloadPermitted: false,
  maxWatchlistAuthors: 15,
  maxWatchlistViews: 15,
  subscriptionType: SubscriptionEnum.PREMIUM_USER,
  messageType: true,
  messageLanguage: true,
  authorType: true,
  messageAsset: true,
  messageKeyEvent: false,
  source: true,
  displayLists: true,
  displayKeyEvents: true,
  displayAuthors: false
}

const PLATINUM_USER: UserPermissions = {
  historyMonths: 12 * 3,
  maxRelativeTimeRangeDays: 90,
  aiTextgen: true,
  downloadPermitted: true,
  maxWatchlistAuthors: 25,
  maxWatchlistViews: 25,
  subscriptionType: SubscriptionEnum.PLATINUM_USER,
  messageType: true,
  messageLanguage: true,
  authorType: true,
  messageAsset: true,
  messageKeyEvent: true,
  source: true,
  displayLists: true,
  displayKeyEvents: true,
  displayAuthors: true
}

const PROFESSIONAL_USER: UserPermissions = {
  historyMonths: 12 * 5,
  maxRelativeTimeRangeDays: 365,
  aiTextgen: true,
  downloadPermitted: true,
  maxWatchlistAuthors: 50,
  maxWatchlistViews: 50,
  subscriptionType: SubscriptionEnum.PROFESSIONAL_USER,
  messageType: true,
  messageLanguage: true,
  authorType: true,
  messageAsset: true,
  messageKeyEvent: true,
  source: true,
  displayLists: true,
  displayKeyEvents: true,
  displayAuthors: true
}

const SUBSCRIPTION_TYPE_PERMISSIONS_MAP: Map<SubscriptionEnum, UserPermissions> = new Map()
SUBSCRIPTION_TYPE_PERMISSIONS_MAP.set(SubscriptionEnum.TRIAL_USER, TRIAL_USER)
SUBSCRIPTION_TYPE_PERMISSIONS_MAP.set(SubscriptionEnum.BASIC_USER, BASIC_USER)
SUBSCRIPTION_TYPE_PERMISSIONS_MAP.set(SubscriptionEnum.PREMIUM_USER, PREMIUM_USER)
SUBSCRIPTION_TYPE_PERMISSIONS_MAP.set(SubscriptionEnum.PLATINUM_USER, PLATINUM_USER)
SUBSCRIPTION_TYPE_PERMISSIONS_MAP.set(SubscriptionEnum.PROFESSIONAL_USER, PROFESSIONAL_USER)

export const FIRST_DAY: Date = new Date(2012, 0, 1, 0, 0, 0)

export default class PermissionService {
  private readonly TIME_SPAN_DAYS_LIMIT = 366

  private readonly userData: Ref<UserData>
  private readonly effectivePermissions: UserPermissions

  constructor () {
    const { user } = storeToRefs(useAuthUserStore())
    this.userData = user

    this.effectivePermissions = this.calculateEffectivePermissions()
  }

  public getFirstDay (): Date {
    const calculatedFirstDay = calculateStartDate(new Date(), { days: 30 * this.effectivePermissions.historyMonths })

    if (isAfter(calculatedFirstDay, FIRST_DAY)) {
      return calculatedFirstDay
    }

    return FIRST_DAY
  }

  public getMaxRelativeTimeRangeDays (): number {
    return this.effectivePermissions.maxRelativeTimeRangeDays
  }

  public isTimeRangePermittedByTimeSpanDays (start: Date, end: Date): boolean {
    // Fractional days are truncated towards zero. Therefore, strict less than max timespan days.
    return differenceInDays(end, start) < this.getMaxRelativeTimeRangeDays()
  }

  public isTimeRangePermittedByHistoryMonths (start: Date): boolean {
    return differenceInDays(new Date(), start) < 31 * this.effectivePermissions.historyMonths
  }

  public getEarliestPermittedStartDateByTimeSpanDays (end: Date): Date {
    return calculateStartDate(end, { days: this.getMaxRelativeTimeRangeDays() })
  }

  public getEarliestPermittedStartDateByHistoryMonths (): Date {
    return calculateStartDate(new Date(), { days: 30 * this.effectivePermissions.historyMonths })
  }

  public getRelativeTimeRangeDays (relativeTimeRangeDays: number): number {
    return Math.min(relativeTimeRangeDays, this.getMaxRelativeTimeRangeDays())
  }

  public getPermittedStartDateByTimeSpanDays (endDate: Date, duration: Duration): Date {
    const startDate = calculateStartDate(endDate, duration)

    if (this.isTimeRangePermittedByTimeSpanDays(startDate, endDate)) {
      return startDate
    }

    return this.getEarliestPermittedStartDateByTimeSpanDays(endDate)
  }

  public getPermittedStartDateByHistoryMonths (duration: Duration): Date {
    const startDate = calculateStartDate(new Date(), duration)

    if (this.isTimeRangePermittedByHistoryMonths(startDate)) {
      return startDate
    }

    return this.getEarliestPermittedStartDateByHistoryMonths()
  }

  public calculatePermittedStartDateByHistoryMonths (endDate: Date, duration: Duration): Date {
    const startDate = calculateStartDate(endDate, duration)

    if (!this.isTimeRangePermittedByHistoryMonths(startDate)) {
      return this.getEarliestPermittedStartDateByHistoryMonths()
    }

    return startDate
  }

  public isRelativeTimeRangePermitted (timeRange: RelativeTimeRange): boolean {
    return this.isDurationPermittedByTimeSpanDays(timeRange.duration)
  }

  public isDurationPermittedByTimeSpanDays (duration: Duration): boolean {
    // Consider max timespan and history months - e.g. time span "Last 1 year" must be disabled if history_months < 12.
    const durationInDays = getDurationInDays(duration)
    const historyMonthsDurationInDays = 31 * this.effectivePermissions.historyMonths
    return durationInDays <= this.getMaxRelativeTimeRangeDays() && durationInDays <= historyMonthsDurationInDays
  }

  public isDurationPermittedByHistoryMonths (duration: Duration): boolean {
    return getDurationInDays(duration) <= 31 * this.effectivePermissions.historyMonths
  }

  public isDownloadPermitted (): boolean {
    return this.effectivePermissions.downloadPermitted
  }

  public isAiTextgenPermitted (): boolean {
    return this.effectivePermissions.aiTextgen
  }

  public isMaxWatchlistAuthorsReached (currentAmount: number): boolean {
    return currentAmount >= this.effectivePermissions.maxWatchlistAuthors
  }

  public getMaxWatchlistAuthors (): number {
    return this.effectivePermissions.maxWatchlistAuthors
  }

  public getMaxWatchlistViews (): number {
    return this.effectivePermissions.maxWatchlistViews
  }

  public isMaxWatchlistViewsReached (currentAmount: number): boolean {
    return currentAmount >= this.effectivePermissions.maxWatchlistViews
  }

  private getDefaultPermissions (subscriptionType: string): UserPermissions {
    if (this.isValidSubscriptionType(subscriptionType)) {
      return SUBSCRIPTION_TYPE_PERMISSIONS_MAP.get(subscriptionType) || TRIAL_USER
    }

    return TRIAL_USER
  }

  public hasRequiredFeatures (features : Feature[]) : boolean {
    return features.every(feature => this.effectivePermissions[feature])
  }

  public hasRequiredTimespan (requiredTimespanOptions : RequiredTimespanOptions) : boolean {
    if (requiredTimespanOptions.duration) {
      return this.isDurationPermittedByTimeSpanDays(requiredTimespanOptions.duration)
    }
    if (requiredTimespanOptions.relativeTimeRange) {
      return this.isRelativeTimeRangePermitted(requiredTimespanOptions.relativeTimeRange)
    }
    if (requiredTimespanOptions.start && !requiredTimespanOptions.end && !requiredTimespanOptions.duration) {
      return this.isTimeRangePermittedByHistoryMonths(requiredTimespanOptions.start)
    }
    if (requiredTimespanOptions.start && requiredTimespanOptions.end) {
      return this.isTimeRangePermittedByTimeSpanDays(requiredTimespanOptions.start, requiredTimespanOptions.end)
    }
    return false
  }

  public fulfillsRequirements (requirements : Requirements) : boolean {
    if (requirements.isAlwaysForbidden) {
      return false
    }

    if (requirements.requiredFeatures) {
      if (!this.hasRequiredFeatures(requirements.requiredFeatures)) {
        return false
      }
    }
    if (requirements.requiredTimeSpan &&
      !this.hasRequiredTimespan(requirements.requiredTimeSpan)) {
      return false
    }

    if (requirements.requiredHistoryMonth &&
      !this.hasRequiredHistoryMonth(requirements.requiredHistoryMonth)) {
      return false
    }

    if (requirements.morningBriefingsHasCapacity) {
      const maxSubscriptions = this.userData.value.max_subscriptions
      const subscriptionCount = this.userData.value.nl?.morning_briefings?.length ?? 0
      return maxSubscriptions > subscriptionCount
    }

    if (requirements.watchlistHasViewCapacity || requirements.watchlistHasAuthorCapacity) {
      const watchlistStore = useWatchlistStore()
      if (requirements.watchlistHasViewCapacity) {
        return !this.isMaxWatchlistViewsReached(watchlistStore.views.length)
      }

      if (requirements.watchlistHasAuthorCapacity) {
        return !this.isMaxWatchlistAuthorsReached(watchlistStore.authors.length)
      }
    }
    return true
  }

  public hasRequiredHistoryMonth (requiredOptions : RequiredHistoryMonthOptions) : boolean {
    return this.isDurationPermittedByHistoryMonths(requiredOptions.duration)
  }

  private calculateEffectivePermissions (): UserPermissions {
    const defaultPermissions = this.getDefaultPermissions(this.userData.value.subscription_type)
    return {
      ...defaultPermissions,
      aiTextgen: defaultPermissions.aiTextgen || this.userData.value.ai_textgen > 0,
      historyMonths: Math.max(defaultPermissions.historyMonths, this.userData.value.history_months),
      maxRelativeTimeRangeDays:
        Math.max(
          defaultPermissions.maxRelativeTimeRangeDays, this.userData.value.timespan_days || this.TIME_SPAN_DAYS_LIMIT
        ),
      downloadPermitted: this.userData.value.subscription_type !== SubscriptionEnum.TRIAL_USER,
      // TODO_NEXT remove default
      maxWatchlistViews: this.userData.value.subscription_type !== SubscriptionEnum.TRIAL_USER
        ? 100
        : defaultPermissions.maxWatchlistViews,
      maxWatchlistAuthors: this.userData.value.subscription_type !== SubscriptionEnum.TRIAL_USER
        ? 100
        : defaultPermissions.maxWatchlistAuthors,
      messageType: this.userData.value?.ui_features?.filters.includes('type'),
      messageLanguage: this.userData.value?.ui_features?.filters.includes('language'),
      authorType: false,
      messageAsset: this.userData.value?.ui_features?.filters.includes('title'),
      messageKeyEvent: this.userData.value?.ui_features?.filters.includes('key_event'),
      source: this.userData.value?.ui_features?.filters.includes('source'),
      displayLists: this.userData.value?.ui_features?.views.includes('list'),
      displayKeyEvents: this.userData.value?.ui_features?.views.includes('key_event'),
      displayAuthors: this.userData.value?.ui_features?.views.includes('author')
    }
  }

  private isValidSubscriptionType (subscriptionType: string): subscriptionType is SubscriptionEnum {
    return subscriptionType in SubscriptionEnum
  }
}
