import { TrackingAction, TrackingContext } from 'boot/plausible'
import { LocationQueryRaw, RouteLocationRaw } from 'vue-router'
import { capitalizeFirstLetter } from 'src/helper/StringHelper'
import { EntityEnum } from 'src/helper/EntityHelper'
import { instanceOfRouteLocationNamedRaw } from 'src/helper/RouterHelper'
import { Feature } from 'src/services/PermissionService'

export abstract class AbstractEntity {
  protected readonly id: number
  protected readonly type: EntityEnum
  protected readonly name: string | undefined
  protected abstract readonly defaultIcon: string
  protected abstract readonly requiredFeatures: Feature[]

  public constructor (entityId: number, entityType: EntityEnum, name?: string) {
    this.id = entityId
    this.type = entityType
    this.name = name
  }

  public getId (): number {
    return this.id
  }

  public getType (): EntityEnum {
    return this.type
  }

  public getRequiredFeatures () : Feature[] {
    return this.requiredFeatures
  }

  public getTypeLabel (): string {
    return capitalizeFirstLetter(this.type.replace('_', ' '))
  }

  public getTooltip (): string {
    return 'Inspect ' + this.getName()
  }

  public getName (): string {
    return this.name || this.getNameFromInventory()
  }

  public getShortName (): string {
    return this.getName()
  }

  protected abstract getNameFromInventory (): string
  public abstract getIcon (): string
  public abstract getSubType(): string
  public abstract getTrackingAction (): TrackingAction
  public abstract getTrackingContext (): TrackingContext
  public abstract getRouteOptions (): RouteLocationRaw

  public getExtendedRouteOptions (additionalQueryParameter: LocationQueryRaw): RouteLocationRaw {
    const basicRouteOptions = this.getRouteOptions()

    if (instanceOfRouteLocationNamedRaw(basicRouteOptions)) {
      return {
        ...basicRouteOptions,
        query: {
          ...additionalQueryParameter,
          ...basicRouteOptions.query
        }
      }
    }

    return basicRouteOptions
  }

  public getExtendedTrackingContext (additionalTrackingContext: TrackingContext): TrackingContext {
    return { ...additionalTrackingContext, ...this.getTrackingContext() }
  }
}
