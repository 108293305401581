import { LocationQueryValue, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { EntityType } from '@stockpulse/typescript-axios'

export enum FooterTab {
  ASSET_INFO = 'asset_info',
  ASSET_MESSAGES = 'asset_messages',
  ASSET_COMMON = 'asset_common',
  VIEW_INFO = 'view_info',
  VIEW_MESSAGES = 'view_messages',
  VIEW_COMMON = 'view_common',
  SETTINGS_WATCHLIST = 'settings_watchlist',
  SETTINGS_PROFILE = 'settings_profile',
  SETTINGS_SUBSCRIPTION = 'settings_subscription',
  SETTINGS_MORNING_BRIEFING = 'settings_morning_briefing',
  SETTINGS_USER_PREFERENCES = 'settings_user_preferences',
  AUTHOR_INFO = 'author_info',
  AUTHOR_MESSAGES = 'author_messages',
  AUTHOR_COMMON = 'author_common',
  STREAM_POSTS_STREAM = 'stream_posts_stream',
  STREAM_POSTS_MARKET_OVERVIEW = 'stream_posts_market_overview',
  STREAM_POSTS_COMMON = 'stream_posts_common'
}

export interface FooterOption {
  label: string,
  icon: string,
  name: FooterTab,
  default?: boolean
}

export interface RouteAccountsProps {
  isIntegrated: boolean
}

export const ROUTE_NAME_HOME = 'home'
export const ROUTE_NAME_LEGACY_HOME = 'legacy_home'
export const ROUTE_NAME_SEARCH = 'search'

export const ROUTE_NAME_INTEGRATED_PRICING = 'integrated_pricing'

export const ROUTE_NAME_LOGIN = 'login'
export const ROUTE_NAME_ACCOUNTS = 'accounts'
export const ROUTE_NAME_PURCHASE = 'purchase'
export const ROUTE_NAME_REGISTER = 'register'
export const ROUTE_NAME_CANCELLATION = 'cancellation'

export const ROUTE_NAME_ASSET = 'asset'
export const ROUTE_NAME_VIEW = 'view'
export const ROUTE_NAME_AUTHOR = 'author'
export const ROUTE_NAME_STREAM_POST = 'post'

export const ROUTE_NAME_INTEGRATED_HOME = 'ihome'

export const ROUTE_NAME_EXPLORE = 'explore'

export const ROUTE_NAME_SETTINGS = 'settings'

export const ROUTE_NAME_TUTORIALS = 'tutorials'

export const ROUTE_NAME_ERROR = 'error'

// Public routes
const ROUTES_WITHOUT_AUTH = [
  ROUTE_NAME_LOGIN,
  ROUTE_NAME_ACCOUNTS,
  ROUTE_NAME_PURCHASE,
  ROUTE_NAME_REGISTER,
  ROUTE_NAME_CANCELLATION,
  ROUTE_NAME_INTEGRATED_PRICING
]

export const ASSET_FOOTER_OPTIONS : FooterOption[] = [
  {
    name: FooterTab.ASSET_INFO,
    icon: 'spi-building',
    label: 'Info'
  },
  {
    name: FooterTab.ASSET_MESSAGES,
    icon: 'spi-message-lines',
    label: 'Messages',
    default: true
  },
  {
    name: FooterTab.ASSET_COMMON,
    icon: 'spi-clapperboard',
    label: 'Common'
  }
]

const VIEW_FOOTER_OPTIONS : FooterOption[] = [
  {
    name: FooterTab.VIEW_INFO,
    icon: 'spi-building',
    label: 'Info'
  },
  {
    name: FooterTab.VIEW_MESSAGES,
    icon: 'spi-message-lines',
    label: 'Messages',
    default: true
  },
  {
    name: FooterTab.VIEW_COMMON,
    icon: 'spi-ellipsis',
    label: 'Common'
  }
]

const AUTHOR_FOOTER_OPTIONS : FooterOption[] = [
  {
    name: FooterTab.AUTHOR_INFO,
    icon: 'spi-user',
    label: 'Info'
  },
  {
    name: FooterTab.AUTHOR_MESSAGES,
    icon: 'spi-message-lines',
    label: 'Messages',
    default: true
  },
  {
    name: FooterTab.AUTHOR_COMMON,
    icon: 'spi-ellipsis',
    label: 'Common'
  }
]

export const SETTING_FOOTER_OPTIONS : FooterOption[] = [
  {
    name: FooterTab.SETTINGS_WATCHLIST,
    icon: 'spi-binoculars',
    label: 'Watchlist'
  },
  {
    name: FooterTab.SETTINGS_PROFILE,
    icon: 'spi-address-card',
    label: 'Profile',
    default: true
  },
  {
    name: FooterTab.SETTINGS_USER_PREFERENCES,
    icon: 'spi-sliders',
    label: 'Preferences'
  },
  {
    name: FooterTab.SETTINGS_SUBSCRIPTION,
    icon: 'spi-credit-card',
    label: 'Subscription'
  },
  {
    name: FooterTab.SETTINGS_MORNING_BRIEFING,
    icon: 'spi-inbox',
    label: 'Morning Briefings'
  }
]

export const STREAM_POSTS_FOOTER_OPTIONS : FooterOption[] = [
  {
    name: FooterTab.STREAM_POSTS_MARKET_OVERVIEW,
    icon: 'spi-money-bill-trend-up',
    label: 'Market Overview'
  },
  {
    name: FooterTab.STREAM_POSTS_STREAM,
    icon: 'spi-comment',
    label: 'Posts',
    default: true
  },
  {
    name: FooterTab.STREAM_POSTS_COMMON,
    icon: 'spi-ellipsis',
    label: 'Common'
  }
]

const routes: RouteRecordRaw[] = [
  // ---------------- Public routes ----------------
  {
    path: '/accounts',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_ACCOUNTS,
        path: '',
        component: () => import('pages/AccountsPage.vue'),
        props: { isIntegrated: false }
      }
    ]
  },
  {
    path: '/purchase',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_PURCHASE,
        path: ':accountType?',
        component: () => import('pages/PurchasePage.vue'),
        props: parseRoutePurchaseProps
      }
    ]
  },
  {
    path: '/register',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_REGISTER,
        path: '',
        component: () => import('pages/RegisterPage.vue')
      }
    ]
  },
  {
    path: '/cancellation',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_CANCELLATION,
        path: '',
        component: () => import('pages/CancellationPage.vue')
      }
    ]
  },
  // ---------------- Routes with authorization ----------------
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_HOME,
        path: '',
        component: () => import('pages/StreamPostsPage.vue')
      }
    ],
    props: {
      displaySourceFilter: false,
      displayNavigation: false,
      footerOptions: STREAM_POSTS_FOOTER_OPTIONS
    }
  },
  {
    path: '/simple-ui-integrated/',
    component: () => import('layouts/IntegratedLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_INTEGRATED_HOME,
        path: '',
        component: () => import('pages/IndexPage.vue')
      }
    ],
    props: {
      displaySearchBar: false
    }
  },

  {
    path: '/search',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_SEARCH,
        path: '',
        component: () => import('pages/SearchPage.vue'),
        props: (route: RouteLocationNormalized): {q: LocationQueryValue | LocationQueryValue[]} => {
          return { q: route.query.q }
        }
      }
    ]
  },
  {
    path: '/explore',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_EXPLORE,
        path: '',
        component: () => import('pages/ExplorePage.vue'),
        props: parseRouteExploreProps
      }
    ],
    props: {
      displayNavigation: true
    }
  },
  {
    path: '/asset',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_ASSET,
        path: '',
        component: () => import('pages/AssetSnapshot.vue'),
        props: parseRouteAssetViewProps
      }
    ],
    props: {
      displaySearchResult: true,
      footerOptions: ASSET_FOOTER_OPTIONS
    }
  },
  {
    path: '/view',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_VIEW,
        path: '',
        component: () => import('pages/ListView.vue'),
        props: parseRouteListViewProps
      }
    ],
    props: {
      displaySearchResult: true,
      footerOptions: VIEW_FOOTER_OPTIONS
    }
  },
  {
    path: '/author',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_AUTHOR,
        path: '',
        component: () => import('pages/AuthorView.vue'),
        props: parseRouteAuthorViewProps
      }
    ],
    props: {
      displaySearchResult: true,
      displaySourceFilter: false,
      footerOptions: AUTHOR_FOOTER_OPTIONS
    }
  },
  {
    path: '/legacy_home',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_LEGACY_HOME,
        path: '',
        component: () => import('pages/IndexPage.vue')
      }
    ],
    props: {
      displaySourceFilter: false,
      displayNavigation: false,
      footerOptions: STREAM_POSTS_FOOTER_OPTIONS
    }
  },
  {
    path: '/post',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_STREAM_POST,
        path: '',
        component: () => import('pages/StreamPostPage.vue'),
        props: parseRouteStreamPostProps
      }
    ],
    props: {
      displaySourceFilter: false,
      displayNavigation: false
    }
  },
  {
    path: '/settings',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_SETTINGS,
        path: '',
        component: () => import('pages/SettingsPage.vue'),
        props: parseRouteSettingsProps
      }
    ],
    props: {
      displayNavigation: false,
      footerOptions: SETTING_FOOTER_OPTIONS
    }
  },
  {
    path: '/tutorials',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_TUTORIALS,
        path: '',
        component: () => import('pages/TutorialPage.vue'),
        props: parseRouteSettingsProps
      }
    ],
    props: {
      displayNavigation: false
    }
  },

  {
    path: '/login',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_LOGIN,
        path: '',
        component: () => import('pages/LoginPage.vue'),
        props: parseRouteLoginProps
      }
    ]
  },

  {
    path: '/pricing',
    component: () => import('layouts/IntegratedLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_INTEGRATED_PRICING,
        path: '',
        component: () => import('pages/AccountsPage.vue'),
        props: { isIntegrated: true }
      }
    ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

// TODO: discuss: currently properties are defined optionally even if they are required,
//  we may redirect the to user to somewhere in this case or the like
// maybe do something like RouteProps<T> { error: bool, element: T } and use layout to display error instead of page

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RouteExploreProps {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function parseRouteExploreProps (route: RouteLocationNormalized) : RouteExploreProps {
  return {}
}
export interface RouteLoginProps { redirect?: string, resetToken?: string }

export function parseRouteLoginProps (route: RouteLocationNormalized) : RouteLoginProps {
  const obj: RouteLoginProps = {}
  if (typeof route.query?.redirect === 'string') {
    obj.redirect = route.query.redirect
  }
  if (typeof route.query?.reset_token === 'string') {
    obj.resetToken = route.query.reset_token
  }
  return obj
}

export enum AccountTypeEntry {
  Basic = 'basic',
  Premium = 'premium',
  Platinum = 'platinum',
  Professional = 'professional'
}

export interface RoutePurchaseProps { accountType?: string }

export function parseRoutePurchaseProps (route: RouteLocationNormalized) : RoutePurchaseProps {
  const accountTypeParam = route.params?.accountType

  if (typeof accountTypeParam === 'string' &&
    Object.values(AccountTypeEntry).includes(accountTypeParam as AccountTypeEntry)) {
    return { accountType: accountTypeParam as AccountTypeEntry }
  }

  return {}
}

export interface SourceFilterProps {
  source: string
}

export interface TimeRangeProps {
  relativeTimeRange?: string,
  startDate?: number,
  endDate?: number
}

export interface MessageFilterProps {
  messageType: string[],
  messageLanguage: string[],
  authorType: string[],
  messageAsset: number[],
  messageKeyEvent: number[]
}

export interface TitleFilterProps {
  list: number[],
  sector: number[],
  exchange: number[],
  region: string[],
  country: string[],
  keyEvent: number[],
  asset: number[],
  type: EntityType[]
}

export type ExtendedMessageFilterProps = MessageFilterProps & SourceFilterProps
export type ExtendedMessageFilterPropsKey = keyof ExtendedMessageFilterProps

export type RouteAssetViewProps = MessageFilterProps & SourceFilterProps & TimeRangeProps & { title_id?: number }

export function parseRouteAssetViewProps (route: RouteLocationNormalized) : RouteAssetViewProps {
  const props : RouteAssetViewProps = {
    title_id: Number(route.query?.title_id),
    messageType: [],
    messageLanguage: [],
    authorType: [],
    messageAsset: [],
    messageKeyEvent: [],
    source: ''
  }

  if (route.query?.relativeTimeRange) {
    props.relativeTimeRange = route.query?.relativeTimeRange as string
  } else if (route.query?.startDate && route.query?.endDate) {
    props.startDate = parseInt(route.query?.startDate as string)
    props.endDate = parseInt(route.query?.endDate as string)
  }

  props.messageKeyEvent.push(...filterNumberOrNumberArray(route.query?.messageKeyEvent))
  props.messageAsset.push(...filterNumberOrNumberArray(route.query?.messageAsset))
  props.messageType.push(...filterStringOrStringArray(route.query?.messageType) as string[])
  props.messageLanguage.push(...filterStringOrStringArray(route.query?.messageLanguage) as string[])
  props.authorType.push(...filterStringOrStringArray(route.query?.authorType) as string[])
  props.source = route.query?.source ? route.query?.source as string : ''

  return props
}

export type RouteListViewProps = TitleFilterProps & MessageFilterProps & SourceFilterProps & {searchQuery: string}

export function parseRouteListViewProps (route: RouteLocationNormalized) : RouteListViewProps {
  const props : RouteListViewProps = {
    list: [],
    sector: [],
    exchange: [],
    region: [],
    country: [],
    keyEvent: [],
    asset: [],
    type: [],
    searchQuery: '',
    messageType: [],
    messageLanguage: [],
    authorType: [],
    messageAsset: [],
    messageKeyEvent: [],
    source: ''
  }
  props.list.push(...filterNumberOrNumberArray(route.query?.list))
  props.sector.push(...filterNumberOrNumberArray(route.query?.sector))
  props.exchange.push(...filterNumberOrNumberArray(route.query?.exchange))
  props.region.push(...filterStringOrStringArray(route.query?.region))
  props.country.push(...filterStringOrStringArray(route.query?.country))
  props.keyEvent.push(...filterNumberOrNumberArray(route.query?.keyEvent))
  props.asset.push(...filterNumberOrNumberArray(route.query?.asset))
  props.type.push(...filterStringOrStringArray(route.query?.type) as EntityType[])
  props.searchQuery = route.query?.searchQuery ? route.query?.searchQuery as string : ''
  props.source = route.query?.source ? route.query?.source as string : ''
  props.messageKeyEvent.push(...filterNumberOrNumberArray(route.query?.messageKeyEvent))
  props.messageAsset.push(...filterNumberOrNumberArray(route.query?.messageAsset))
  props.messageType.push(...filterStringOrStringArray(route.query?.messageType) as string[])
  props.messageLanguage.push(...filterStringOrStringArray(route.query?.messageLanguage) as string[])
  props.authorType.push(...filterStringOrStringArray(route.query?.authorType) as string[])
  return props
}

function filterNumberOrNumberArray (input: unknown): number[] {
  if (!input || (Array.isArray(input) && input.length === 0)) {
    return []
  }
  const singleElement = Number(input)
  if (Number.isSafeInteger(singleElement)) {
    return [singleElement]
  }
  if (Array.isArray(input)) {
    return input.reduce((acc: number[], element: unknown) => {
      const num = Number(element)
      if (Number.isSafeInteger(num)) {
        acc.push(num)
      }
      return acc
    }, [])
  }
  return []
}

function filterStringOrStringArray (input: unknown): string[] {
  if (!input) {
    return []
  }
  if (typeof input === 'string') {
    return [input]
  }
  if (Array.isArray(input)) {
    return input.reduce((acc: string[], element: unknown) => {
      if (typeof element === 'string') {
        acc.push(element)
      }
      return acc
    }, [])
  }
  return []
}

export type RouteAuthorViewProps = MessageFilterProps & { name?: string, authorSource?: string }

export function parseRouteAuthorViewProps (route: RouteLocationNormalized) : RouteAuthorViewProps {
  const props : RouteAuthorViewProps = {
    name: typeof route.query?.name === 'string' ? route.query.name : undefined,
    authorSource: typeof route.query?.authorSource === 'string' ? route.query.authorSource : undefined,
    messageType: [],
    messageLanguage: [],
    authorType: [],
    messageAsset: [],
    messageKeyEvent: []
  }
  props.messageKeyEvent.push(...filterNumberOrNumberArray(route.query?.messageKeyEvent))
  props.messageAsset.push(...filterNumberOrNumberArray(route.query?.messageAsset))
  props.messageType.push(...filterStringOrStringArray(route.query?.messageType) as string[])
  props.messageLanguage.push(...filterStringOrStringArray(route.query?.messageLanguage) as string[])
  props.authorType.push(...filterStringOrStringArray(route.query?.authorType) as string[])

  return props
}

export interface RouteSettingsProps { tab?: FooterTab }

function parseRouteSettingsProps (route: RouteLocationNormalized) : RouteSettingsProps {
  const tab = route.query?.tab
  if (typeof tab === 'string' &&
    Object.values(FooterTab).includes(tab as FooterTab)) {
    return { tab: tab as FooterTab }
  }
  return {}
}

export interface RouteStreamPostProps {
  post_id?: string
}
function parseRouteStreamPostProps (route: RouteLocationNormalized) : RouteStreamPostProps {
  return { post_id: typeof route.query?.post_id === 'string' ? route.query.post_id : undefined }
}

export function routeRequiresAuthorization (route : string) : boolean {
  return !ROUTES_WITHOUT_AUTH.includes(route)
}

export default routes
