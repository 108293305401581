import { defineStore, storeToRefs } from 'pinia'
import { ref, Ref, watch, computed } from 'vue'
import { useAuthUserStore } from 'stores/auth-user-store'
import { socket } from '../services/socket'
import * as crypto from 'crypto-js'
import { Platform } from 'quasar'
import { DisconnectDescription, Socket } from 'socket.io-client/build/esm/socket'
import { RealTimeReportType } from 'src/helper/ReportHelper'

export enum WebSocketAuthStatus {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED'
}

export enum WebSocketStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

type LastMessage = { timestamp: number | null, message: string | null }

export type UnsubscribeCallback = () => void;

export interface SocketAcknowledgeCallback { (error: unknown, data: unknown): void }
export interface SocketMessageListener { (data: unknown, error: unknown): void }

enum WebSocketClientMessage {
  SUBSCRIBE_WATCHLIST = 'subscribe watchlist',
  SUBSCRIBE_TITLE_MESSAGES = 'subscribe title messages',
  SUBSCRIBE_TITLE = 'subscribe title',
  SUBSCRIBE_TITLE_TICKS = 'subscribe title ticks',
  UNSUBSCRIBE_TITLE = 'unsubscribe title',
  SUBSCRIBE_TITLE_PARTITION = 'subscribe title partition',
  UNSUBSCRIBE_TITLE_PARTITION = 'unsubscribe title partition',
  SUBSCRIBE_STD_SIGNALS = 'subscribe std signals',
  SUBSCRIBE_SIGNALS = 'subscribe signals',
  SUBSCRIBE_POSTS = 'subscribe stream posts',
  AUTHORIZE = 'authorize'
}
enum WebSocketEvent {
  MESSAGE = 'message',
  STD_SIGNAL = 'std_signal',
  SIGNAL = 'signal',
  BUZZ = 'buzz',
  TICK = 'tick',
  POST = 'post'
}

export const webSocketStore = defineStore('webSocket',
  () => {
    const authUserStore = useAuthUserStore()
    const { hasValidSubscription } = storeToRefs(authUserStore)

    const lastData : Ref<LastMessage> = ref({ timestamp: null, message: null })

    const socketStatus : Ref<WebSocketStatus> = ref(WebSocketStatus.CLOSED)
    const authStatus : Ref<WebSocketAuthStatus> = ref(WebSocketAuthStatus.UNAUTHENTICATED)

    const registeredListeners: SocketMessageListener[] = []

    const isSocketReady = computed(() => {
      return socketStatus.value === WebSocketStatus.OPEN && authStatus.value === WebSocketAuthStatus.AUTHENTICATED
    })

    // TODO: example binds the events via boot file.
    // As long as the websocket store does not leave the scope, binding here seems to work

    socket.on('connect', onConnected)
    socket.on('connect_error', onConnectError)
    socket.on('disconnect', onDisconnected)

    socket.onAny(onAnyEvent)

    function onDisconnected (reason: Socket.DisconnectReason, description?: DisconnectDescription): void {
      authStatus.value = WebSocketAuthStatus.UNAUTHENTICATED
      socketStatus.value = WebSocketStatus.CLOSED
    }

    // eslint-disable-next-line n/handle-callback-err
    function onConnectError (err: Error): void {
      authStatus.value = WebSocketAuthStatus.UNAUTHENTICATED
      socketStatus.value = WebSocketStatus.CLOSED
    }

    function onAnyEvent (eventName: string): void {
      lastData.value.timestamp = Date.now()
      lastData.value.message = eventName
    }

    const fingerprint =
      process.env.WEBSOCKET_FINGERPRINT || crypto.MD5(JSON.stringify(Platform.is)).toString(crypto.enc.Hex)

    function onConnected (): void {
      socketStatus.value = WebSocketStatus.OPEN
      // TODO_NEXT: remove plain text password usage in websocket, avoid caching plain text credentials in local storage
      socket.emit(WebSocketClientMessage.AUTHORIZE, {
        username: authUserStore.getCredentials?.username,
        password: authUserStore.getCredentials?.password,
        fingerprint
      }, (err: unknown, data: unknown) => {
        if (err || !data) {
          console.log('authorize failed', err, data)
          authStatus.value = WebSocketAuthStatus.UNAUTHENTICATED
          socket.close()
        } else if (data) {
          authStatus.value = WebSocketAuthStatus.AUTHENTICATED
        }
      })
    }

    function emitSubscribeWatchlist (watchlistId: number, callback: SocketAcknowledgeCallback): void {
      socket.emit(WebSocketClientMessage.SUBSCRIBE_WATCHLIST, watchlistId, callback)
    }

    function emitSubscribeTitlesPartition (
      titleIds: number[],
      partition: string,
      listener: SocketMessageListener,
      callback?: SocketAcknowledgeCallback) : UnsubscribeCallback {
      if (titleIds.length === 0) {
        return () => {}
      }
      return emitSubscribeTitlePartition(titleIds.join(','), partition, listener, callback)
    }

    function emitSubscribeTitlePartition (
      titleId: number|string,
      partition: string,
      listener: SocketMessageListener,
      callback?: SocketAcknowledgeCallback) : UnsubscribeCallback {
      if (!registeredListeners.includes(listener)) {
        registeredListeners.push(listener)
        socket.on(WebSocketEvent.BUZZ, listener)
      }
      if (partition !== RealTimeReportType.REALTIME) {
        socket.emit(WebSocketClientMessage.SUBSCRIBE_TITLE_PARTITION, titleId, partition, callback)
      } else {
        socket.emit(WebSocketClientMessage.SUBSCRIBE_TITLE, titleId, callback)
      }

      return () => socket.off(WebSocketClientMessage.SUBSCRIBE_TITLE_PARTITION, listener)
    }

    function emitUnsubscribeTitlesPartition (titleIds: number[], partition: string) : void {
      if (titleIds.length === 0) {
        return
      }
      socket.emit(WebSocketClientMessage.UNSUBSCRIBE_TITLE_PARTITION, titleIds.join(','), partition)
    }

    function emitUnsubscribeTitles (titleIds: number[]) : void {
      if (titleIds.length === 0) {
        return
      }
      socket.emit(WebSocketClientMessage.UNSUBSCRIBE_TITLE, titleIds.join(','))
    }

    function emitSubscribeStdSignals (listener: SocketMessageListener, callback?: SocketAcknowledgeCallback)
          : UnsubscribeCallback {
      socket.on(WebSocketEvent.STD_SIGNAL, listener)
      socket.emit(WebSocketClientMessage.SUBSCRIBE_STD_SIGNALS, callback)

      return () => socket.off(WebSocketClientMessage.SUBSCRIBE_STD_SIGNALS, listener)
    }

    function emitSubscribeSignals (listener: SocketMessageListener, callback?: SocketAcknowledgeCallback)
          : UnsubscribeCallback {
      socket.on(WebSocketEvent.SIGNAL, listener)
      socket.emit(WebSocketClientMessage.SUBSCRIBE_SIGNALS, callback)

      return () => socket.off(WebSocketClientMessage.SUBSCRIBE_SIGNALS, listener)
    }

    function emitSubscribePosts (listener: SocketMessageListener): void {
      socket.on(WebSocketEvent.POST, listener)
      socket.emit(WebSocketClientMessage.SUBSCRIBE_POSTS)
    }

    function emitSubscribeTitleMessages (
      titleId: number|string,
      listener: SocketMessageListener,
      callback?: SocketAcknowledgeCallback) : UnsubscribeCallback {
      if (!registeredListeners.includes(listener)) {
        registeredListeners.push(listener)
        socket.on(WebSocketEvent.MESSAGE, listener)
      }

      socket.emit(WebSocketClientMessage.SUBSCRIBE_TITLE_MESSAGES, titleId, callback)

      return () => socket.off(WebSocketClientMessage.SUBSCRIBE_TITLE_MESSAGES, listener)
    }

    function emitSubscribeTitlesMessages (
      titleIds: number[],
      listener: SocketMessageListener,
      callback?: SocketAcknowledgeCallback) : UnsubscribeCallback {
      if (titleIds.length === 0) {
        return () => {}
      }
      return emitSubscribeTitleMessages(titleIds.join(','), listener, callback)
    }

    function emitSubscribeTitlesTicks (
      titleIds: number[],
      listener: SocketMessageListener,
      callback?: SocketAcknowledgeCallback
    ) : UnsubscribeCallback {
      if (titleIds.length === 0) {
        return () => {}
      }
      if (!registeredListeners.includes(listener)) {
        registeredListeners.push(listener)
        socket.on(WebSocketEvent.TICK, listener)
      }

      socket.emit(WebSocketClientMessage.SUBSCRIBE_TITLE_TICKS, titleIds, callback)

      return () => socket.off(WebSocketClientMessage.SUBSCRIBE_TITLE_TICKS, listener)
    }

    function emitSubscribeTitleTicks (
      titleId: number|string,
      listener: SocketMessageListener,
      callback?: SocketAcknowledgeCallback
    ) : UnsubscribeCallback {
      if (!registeredListeners.includes(listener)) {
        registeredListeners.push(listener)
        socket.on(WebSocketEvent.TICK, listener)
      }

      socket.emit(WebSocketClientMessage.SUBSCRIBE_TITLE_TICKS, titleId, callback)

      return () => socket.off(WebSocketClientMessage.SUBSCRIBE_TITLE_TICKS, listener)
    }

    const onhasValidSubscription = (newValue: boolean): void => {
      if (newValue) {
        socket.open()
      } else {
        authStatus.value = WebSocketAuthStatus.UNAUTHENTICATED
        socketStatus.value = WebSocketStatus.CLOSED
        socket.close()
      }
    }

    // trigger login check immediate, to open socket after auth store hydration
    watch(hasValidSubscription, onhasValidSubscription, { immediate: true })

    return {
      socketStatus,
      authStatus,
      isSocketReady,
      lastData,
      emitSubscribeStdSignals,
      emitSubscribePosts,
      emitSubscribeSignals,
      emitSubscribeWatchlist,
      emitSubscribeTitleMessages,
      emitSubscribeTitlesMessages,
      emitUnsubscribeTitles,
      emitSubscribeTitlesPartition,
      emitUnsubscribeTitlesPartition,
      emitSubscribeTitleTicks,
      emitSubscribeTitlesTicks
    }
  })

/*
TODO: check what else needs to be implemented
Dashboard known events:
Socket.on('new_stats', function(data) {
Socket.on('fingerprint', function(data) {
Socket.on('complete order', function(data) {
Socket.on('strategy_signal', function(data) {
Socket.on('backtest error', function(resp) {
Socket.on('backtest result', function(resp) {
Socket.on('report', function(data) {
Socket.on('message', function(data) {
Socket.on('std_signal', function(data) {
Socket.on('tick', function(data) {
Socket.on('buzz', function(data) {
Socket.on('title status', function(data) {
Socket.on('signal', function(data) {
 */
